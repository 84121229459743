import React from "react";
import { FiSearch } from "react-icons/fi";
import  Typewriter from 'typewriter-effect'

import { images } from "../../../constants";

const Hero = () => {
  

  return (
    <section className="container flex flex-col px-5 py-5 mx-auto lg:flex-row">
      <div className="mt-10 lg:w-1/2">
        {/* <h1 className="font-roboto text-3xl text-center font-bold text-dark-soft md:text-5xl lg:text-4xl xl:text-5xl lg:text-left lg:max-w-[540px]">
        Welcome To Albatross
        </h1> */}
        <div className="font-roboto text-3xl text-center font-bold text-dark-soft md:text-5xl lg:text-4xl xl:text-5xl lg:text-left lg:max-w-[540px]">
        Welcome To
        <Typewriter  
        options={{
          autoStart:true,
          loop: true,
          delay: 75,
          strings:[
            " Islamabad...",
            " Albatross Realtor..."
          ]
        }}/>
        </div>
        <p className="mt-4 text-justify text-dark-light md:text-xl lg:text-base xl:text-xl lg:text-left">
        AlbatrossRealtor is a real estate company that provides a wide range of services to buyers, sellers, and investors in Islamabad, Pakistan. We are committed to helping our clients achieve their real estate goals, whether they are looking to buy a new home, sell their current home, or invest in real estate.
        </p>
        <div className="flex flex-col gap-y-2.5 mt-10 lg:mt-6 xl:mt-10 relative">
          <div className="relative">
            <FiSearch className="absolute left-3 top-1/2 -translate-y-1/2 w-6 h-6 text-[#959EAD]" />
            <input
              className="placeholder:font-bold font-semibold text-dark-soft placeholder:text-[#959EAD] rounded-lg pl-12 pr-3 w-full py-3 focus:outline-none shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] md:py-4"
              type="text"
              placeholder="Search article"
            />
          </div>
          <button className="w-full px-5 py-3 font-semibold text-white rounded-lg bg-primary md:absolute md:right-2 md:top-1/2 md:-translate-y-1/2 md:w-fit md:py-2">
            Search
          </button>
        </div>
        <div className="flex flex-col mt-4 lg:flex-row lg:items-start lg:flex-nowrap lg:gap-x-4 lg:mt-7">
          <span className="mt-2 italic font-semibold text-dark-light lg:mt-4 lg:text-sm xl:text-base">
            Popular Tags:
          </span>
          <ul className="flex flex-wrap gap-x-2.5 gap-y-2.5 mt-3 lg:text-sm xl:text-base">
            <li className="rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 text-primary font-semibold">
              Design
            </li>
            <li className="rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 text-primary font-semibold">
              User Experience
            </li>
            <li className="rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 text-primary font-semibold">
              User Interfaces
            </li>
          </ul>
        </div>
      </div>
      <div className="hidden lg:block lg:1/2 ">
        <img
        // rounded-tl-full rounded-tr-full
          className="w-[600px]  border-blue-700 border-t-2 ml-9 rounded-md shadow-2xl shadow-blue-500/50 "
          src={images.isl}
          alt="users are reading articles"
        />
      </div>
    </section>
  );
};

export default Hero;
